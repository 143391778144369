import React from 'react'
import Layout from '../components/Layout'

export default class IndexPage extends React.Component {
  render() {
    // const { data } = this.props

    return (
      <Layout>
        <section className="books container">
          <div className="item">
            <div className="box">
              <img src="/img/jos_olet_jumalanpoika_etukansi.jpg" alt="Jos olet Jumalan Poika"/>
            </div>
            <div className="cta">
              <span>
                <b>3,99</b> EUR
              </span>
              <span>
                <a
                  href="https://holvi.com/shop/juliablum/product/b4e68555341496effe845b9affe5dd76/"
                  className="button is-light is-medium"
                  target="blank"
                  rel="noopener"
                >
                  Osta
                </a>
              </span>
            </div>
          </div>

          <div className="item">
            <div className="box">
              <img src="/img/han_joka_minut_nakee_etukansi.jpg" alt="Hän, joka näkee minut elää" />
            </div>
            <div className="cta">
              <span>
                <b>2,99</b> EUR
                </span>
              <span>
                <a
                  href="https://holvi.com/shop/juliablum/product/f96eb1c000f6b553595956c0d4c9924f/"
                  className="button is-light is-medium"
                  target="blank"
                  rel="noopener"
                >
                  Osta
                </a>
              </span>
            </div>
          </div>

          <div className="item">
            <div className="box" style={{ backgroundColor: '#AF8C65' }}>
              <img src="/img/avaimia_etukansi.jpg" alt="Avaimia Raamatun ymmärtämiseen" />
            </div>
            <div className="cta">
              <span>
                <b>2,99</b> EUR
                </span>
              <span>
                <a
                  href="https://holvi.com/shop/juliablum/product/99a0bdf3251a7c63edcd53e7cc2affc2/"
                  className="button is-light is-medium"
                  target="blank"
                  rel="noopener"
                >
                  Osta
                </a>
              </span>
            </div>
          </div>

          <div className="item">
            <div className="box">
              <img src="/img/abraham_etukansi.jpg" alt="Abrahamilla oli kaksi poikaa" />
            </div>
            <div className="cta">
              <span>
                <b>3,99</b> EUR
                </span>
              <span>
                <a
                  href="https://holvi.com/shop/juliablum/product/72bfa6e5ee7a929858d7d5aa2abf855c/"
                  className="button is-light is-medium"
                  target="blank"
                  rel="noopener"
                >
                  Osta
                </a>
              </span>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
